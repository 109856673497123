<template>
  <form-history-layout
    type="withdraw"
    :items="list"
  >
    <template v-slot:formLayout>
      <div v-if="withdraw_open">
        <div class="d-flex align-center">
          <div class="flex-grow-1">
            <div class="white--text text-end mx-3">
              {{ $t('message.game_balance', {amount: game_balance}) }}
            </div>
            <div class="white--text text-end mx-3">
              {{ $t('message.can_withdraw', { amount: main_balance }) }}
            </div>
          </div>
          <v-btn @click="credit_move" :loading="isMoveLoading"> {{ $t('common.credit_move')}} </v-btn>
        </div>

        <v-form ref="form"
          :disabled="isLoading || !canWithdraw"
          @keyup.native.enter.prevent="submit"
          @submit.prevent=""
        >
          <s-label-text
            v-model="amount"
            background-color="#236BB5"
            class="textInputDark my-2"
            type="number"
            :label_text="$t('withdraw.amount')"
            :rules="rules.amount"
            outlined
            dense
            dark
            input
          >
            <template v-slot:append-outer>
              <div class="step mt-1">1</div>
            </template>
          </s-label-text>
        </v-form>

        <div class="d-flex align-center my-5">
          <hr class="flex-grow-1 mr-2"/>
          <h3 style="color: rgb(229, 185, 87);">เข้าบัญชี</h3>
          <hr class="flex-grow-1 ml-2"/>
        </div>

        <v-card :class="[profile.bank_name, 'bank', 'mx-5']">
          <v-card-text>
            <div class="d-flex justify-end align-center">
              <div class="white--text mx-2">{{ $t(`bank.${profile.bank_name}`) }}</div>
              <img :src="`/images/banks/${profile.bank_name}.png`" widht="25" height="25" />
            </div>
            <h2 class="my-5 white--text mx-2 text-center">{{profile.bank_number}}</h2>
            <div class="mt-2 white--text text-center">
              {{profile.bank_user}}
            </div>
          </v-card-text>
        </v-card>

        <div class="d-flex mt-5">
        <v-btn class="btnSubmit mt-0 flex-grow-1" @click="submit" :loading="isLoading" :disabled="isLoading || !canWithdraw">{{ $t('withdraw.notification') }}</v-btn>
        <div class="step ml-3 d-inline-block" style="padding-top: 3px!important;">2</div>
        </div>
        <div v-if="!canWithdraw" class="red--text text-center">ต้องทำ turnover อีก {{ profile && profile.turnover_promotion.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') || 0 }} </div>
      </div>
      <div v-else class="text-center">
        <v-img src="/images/logo_ak55bet.png" />
        <h2>ระบบถอนเงินปิดปรับปรุงชั่วคราว</h2>
      </div>
    </template>

    <template v-slot:[`item.status`]="{ item }">
      <span v-if="item.complete">{{ $t('withdraw.success') }}</span>
      <span v-else-if="item.confirm">{{ $t('withdraw.confirm') }}</span>
      <span v-else-if="!item.active">{{ $t('withdraw.cancel') }}</span>
      <span v-else>{{ $t('withdraw.waiting') }}</span>
    </template>

  </form-history-layout>
</template>

<script>
import FormHistoryLayout from '../../layouts/sub_layouts/FormHistoryLayout.vue'
import { mapGetters, mapActions } from 'vuex'
import validate from '../../helper/validates'
export default {
  components: {
    FormHistoryLayout
  },
  data() {
    return {
      isMoveLoading: false,
      amount: 0,
      isLoading: false,
    }
  },
  created() {},
  async mounted() {
    this.page_loading(true)
    await this.get_balance()
    this.page_loading(false)
  },
  methods: {
    ...mapActions('app', ['showSuccess', 'page_loading']),
    ...mapActions('withdraw', ['create']),
    ...mapActions('game', ['creditMove']),
    ...mapActions('member', ['get_balance']),
    async credit_move() {
      this.isMoveLoading = true
      await this.creditMove()
      this.isMoveLoading = false
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.isLoading = true
        await this.create(this.amount)
          .then(async (data) => {
            if (!data.error) {
              await this.get_balance()
              this.showSuccess(this.$t('message.withdraw_send_success'))
            }
          })
        this.isLoading = false
      }
    }
  },
  computed: {
    ...mapGetters('app', ['withdraw_open']),
    ...mapGetters('member', ['profile', 'balance', 'main_balance', 'game_balance']),
    ...mapGetters('withdraw', ['withdrawQueues', 'withdraws']),
    canWithdraw() {
      if (!this.profile) return false
      if (this.profile.turnover_promotion > 0) return false
      return true
    },
    rules() {
      return {
        amount: [
          validate.required(),
          validate.number(100, this.balance && this.balance.find ? this.balance.find(r=>r.id == 'MAIN').value || 0 : 0)
        ]
      }
    },
    list() {
      return [...this.withdrawQueues, ...this.withdraws].sort((a, b) => {
        const aUpdate = a.id
        const bUpdate = b.id

        if (aUpdate > bUpdate) return -1
        else if (aUpdate < bUpdate) return 1
        else return 0
      }).slice(0, 10)
    }
  }
}
</script>

<style scoped>
  .textInputDark /deep/ input{
    color: white !important;
  }
</style>
